var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var M3,O3,Q3,R3,S3,T3,U3,Fra;M3=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=M3[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=M3._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.metric",a);}return a};$CLJS.N3=function(a,b){return M3($CLJS.$Z(a),b)};
O3=function(a,b,c){var d=$CLJS.CV(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.ZE);d=$CLJS.pG($CLJS.Wj.j($CLJS.Tg([c]),$CLJS.xD,$CLJS.dd),d);if(!$CLJS.n(d))throw $CLJS.Uh($CLJS.gD("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.sr,c,$CLJS.FE,a,$CLJS.CK,b],null));return d};$CLJS.P3=function(a){return $CLJS.E1($CLJS.vF,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};Q3=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);
R3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);S3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);T3=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);U3=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);
Fra=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.M_.m(null,$CLJS.ZE,function(a,b){var c=$CLJS.Be($CLJS.ZE.h($CLJS.CV(a,b)));return $CLJS.n(c)?$CLJS.H1($CLJS.ID("and"),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.i_.v(a,b,v,$CLJS.j_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.i_.v(a,
b,t,$CLJS.j_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):null});$CLJS.P_.m(null,$CLJS.ZE,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.tA);e=$CLJS.J.g(e,$CLJS.ki);c=$CLJS.I(c,2,null);c=O3(a,b,c);return $CLJS.ok.l($CLJS.H([$CLJS.g_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.KJ,$CLJS.hK,$CLJS.A0,$CLJS.xD.h($CLJS.dd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.tA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.ki,e],null):null]))});
$CLJS.J_.m(null,$CLJS.ZE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.i_.v(a,b,O3(a,b,c),d)});$CLJS.pE(S3,U3);
for(var V3=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.kF,$CLJS.sF],null)),W3=null,X3=0,Y3=0;;)if(Y3<X3){var Gra=W3.X(null,Y3);$CLJS.pE(Gra,S3);Y3+=1}else{var Z3=$CLJS.y(V3);if(Z3){var $3=Z3;if($CLJS.vd($3)){var a4=$CLJS.ic($3),Hra=$CLJS.jc($3),Ira=a4,Jra=$CLJS.D(a4);V3=Hra;W3=Ira;X3=Jra}else{var Kra=$CLJS.z($3);$CLJS.pE(Kra,S3);V3=$CLJS.B($3);W3=null;X3=0}Y3=0}else break}
$CLJS.J_.m(null,S3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.i_.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.gD("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.gD("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.ID("Count");case "cum-count":return $CLJS.ID("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.K_.m(null,S3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.P_.m(null,T3,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.iH($CLJS.P_,U3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.kj,$CLJS.RB)});
$CLJS.pE(T3,U3);$CLJS.pE(S3,T3);$CLJS.pE($CLJS.OE,T3);$CLJS.J_.m(null,$CLJS.SE,function(){return $CLJS.ID("Case")});$CLJS.K_.m(null,$CLJS.SE,function(){return"case"});$CLJS.pE(R3,U3);
for(var b4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dF,$CLJS.EF,$CLJS.OE,$CLJS.tj,$CLJS.wF,$CLJS.ni,$CLJS.xF,$CLJS.vF,$CLJS.PE],null)),c4=null,d4=0,e4=0;;)if(e4<d4){var Lra=c4.X(null,e4);$CLJS.pE(Lra,R3);e4+=1}else{var f4=$CLJS.y(b4);if(f4){var g4=f4;if($CLJS.vd(g4)){var h4=$CLJS.ic(g4),Mra=$CLJS.jc(g4),Nra=h4,Ora=$CLJS.D(h4);b4=Mra;c4=Nra;d4=Ora}else{var Pra=$CLJS.z(g4);$CLJS.pE(Pra,R3);b4=$CLJS.B(g4);c4=null;d4=0}e4=0}else break}
$CLJS.K_.m(null,R3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.J_.m(null,R3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.i_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.gD("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.gD("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.gD("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.gD("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.gD("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.gD("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.gD("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.gD("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.gD("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.J_.m(null,$CLJS.VE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.gD("{0}th percentile of {1}",$CLJS.H([c,$CLJS.i_.v(a,b,e,d)]))});$CLJS.K_.m(null,$CLJS.VE,function(){return"percentile"});
$CLJS.pE(Q3,U3);for(var i4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VE,$CLJS.PE],null)),j4=null,k4=0,l4=0;;)if(l4<k4){var Qra=j4.X(null,l4);$CLJS.pE(Qra,Q3);l4+=1}else{var m4=$CLJS.y(i4);if(m4){var n4=m4;if($CLJS.vd(n4)){var o4=$CLJS.ic(n4),Rra=$CLJS.jc(n4),Sra=o4,Tra=$CLJS.D(o4);i4=Rra;j4=Sra;k4=Tra}else{var Ura=$CLJS.z(n4);$CLJS.pE(Ura,Q3);i4=$CLJS.B(n4);j4=null;k4=0}l4=0}else break}
$CLJS.P_.m(null,Q3,function(a,b,c){var d=$CLJS.Pj.g,e=$CLJS.iH($CLJS.P_,U3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.Pj,a,$CLJS.kj)});$CLJS.J_.m(null,$CLJS.CF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.gD("Sum of {0} matching condition",$CLJS.H([$CLJS.i_.v(a,b,e,d)]))});
$CLJS.K_.m(null,$CLJS.CF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.L_.j(a,b,d))].join("")});$CLJS.pE($CLJS.CF,U3);$CLJS.J_.m(null,$CLJS.qF,function(){return $CLJS.ID("Share of rows matching condition")});$CLJS.K_.m(null,$CLJS.qF,function(){return"share"});$CLJS.P_.m(null,$CLJS.qF,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.iH($CLJS.P_,U3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.kj,$CLJS.iC)});
$CLJS.pE($CLJS.qF,U3);$CLJS.J_.m(null,$CLJS.sF,function(){return $CLJS.ID("Count of rows matching condition")});$CLJS.P_.m(null,U3,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.ok.l;d=$CLJS.n(d)?$CLJS.Cl($CLJS.g_.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YJ,$CLJS.kj],null)):null;var f=$CLJS.iH($CLJS.P_,$CLJS.Oh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.ok,$CLJS.H([d,a]))});
$CLJS.Vra=function(){function a(d){return $CLJS.E1($CLJS.Rw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.E1($CLJS.Rw,$CLJS.xf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.lV.m(null,$CLJS.ZE,function(a){return a});
$CLJS.p4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.WA(f),$CLJS.OJ))f=$CLJS.nV(f);else return $CLJS.J1(d,e,$CLJS.ZE,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.q4=function(){function a(d,e){return $CLJS.Be($CLJS.ZE.h($CLJS.CV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.r4=function(){function a(d,e){var f=$CLJS.Be($CLJS.ZE.h($CLJS.CV(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(k){var l=$CLJS.g_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.tA.h(l);return m.call($CLJS.R,$CLJS.D1(l,$CLJS.ki,$CLJS.n(t)?t:$CLJS.Xi),$CLJS.KJ,$CLJS.hK,$CLJS.H([$CLJS.A0,$CLJS.xD.h($CLJS.dd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.J_.m(null,$CLJS.QF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.MF);return $CLJS.lD.h(a.o?a.o():a.call(null))});$CLJS.R_.m(null,$CLJS.QF,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.cF);b=$CLJS.J.g(d,$CLJS.MF);c=$CLJS.J.g(d,$CLJS.LF);d=$CLJS.J.g(d,$CLJS.KY);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.H_,$CLJS.$z(a),$CLJS.H([$CLJS.F_,c]));return null!=d?$CLJS.R.j(a,$CLJS.q_,d):a});
$CLJS.Wra=function(){function a(d,e){var f=function(){var m=$CLJS.dz.h($CLJS.W0(d));return $CLJS.n(m)?m:$CLJS.Sg}(),k=$CLJS.CV(d,e),l=$CLJS.V_.j(d,e,k);return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.Wj.j($CLJS.ef(function(m){m=$CLJS.OF.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.ul(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.LF),u=$CLJS.J.g(m,$CLJS.JF);if($CLJS.Ra(t))return m;if($CLJS.E.g(u,$CLJS.ur))return $CLJS.R.j(m,$CLJS.zV,l);t=$CLJS.Be($CLJS.v0(function(v){return $CLJS.AZ(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.zV,t):null}),$CLJS.cf.h(function(m){return $CLJS.R.j(m,$CLJS.Vs,$CLJS.QF)})),$CLJS.RF))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Xra=function(){function a(d,e){return $CLJS.mV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF.h(d),$CLJS.N,$CLJS.oV.h(e)],null))}function b(d){if($CLJS.Ra($CLJS.LF.h(d)))return $CLJS.mV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF.h(d),$CLJS.N],null));var e=$CLJS.cF.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.Uh(e,new $CLJS.h(null,1,[Fra,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();