import { t } from "ttag";
import {useCallback, useMemo} from "react";
import {
  Form,
  FormErrorMessage,
  FormProvider,
  FormSubmitButton,
  FormTextarea,
  FormTextInput
} from "metabase/forms";
import Breadcrumbs from "metabase/components/Breadcrumbs";
import {updateKeycloakSettings} from "metabase/admin/settings/settings";
import {connect} from "react-redux";
import type {SettingElement} from "metabase/admin/settings/types";
import _ from "underscore";
import type {SettingValue} from "metabase-types/api";
import {
  KeycloakFormFooter,
  KeycloakFormSection
} from "metabase/admin/settings/audaxis/components/AudaxisAuthForm/AudaxisAuthForm.styled";
import {Stack} from "metabase/ui";
import {FormSection} from "metabase/containers/FormikForm";
import {settingToFormField} from "metabase/admin/settings/utils";
import MetabaseSettings from "metabase/lib/settings";
import GroupMappingsWidget from "metabase/admin/settings/containers/GroupMappingsWidget";

export type SettingValues = { [key: string]: SettingValue };

type KeycloakFormSettingElement = Omit<SettingElement, "key"> & {
  key: string; // ensuring key is required
  is_env_setting?: boolean;
  env_name?: string;
  default?: any;
};

type Props = {
  elements: KeycloakFormSettingElement[];
  settingValues: SettingValues;
  onSubmit: (values: SettingValues) => void;
};

export const AudaxisAuthFormView = ({
  elements = [],
  settingValues,
  onSubmit,
                                    }: Props) => {
  const isEnabled = Boolean(settingValues["keycloak-enabled"]);

  const settings = useMemo(() => {
    return _.indexBy(elements, "key");
  }, [elements]);

  const fields = useMemo(() => {
    return _.mapObject(settings, settingToFormField);
  }, [settings]);

  const defaultValues = useMemo(() => {
    return _.mapObject(settings, "default");
  }, [settings]);

  const attributeValues = useMemo(() => {
    return getAttributeValues(settingValues, defaultValues);
  }, [settingValues, defaultValues]);

  const FAKE_KEYCLOAK_URL_KEY = "FAKE_KEYCLOAK_URL_KEY";

  const handleSubmit = useCallback(
    values => {
      const { [FAKE_KEYCLOAK_URL_KEY]: _, ...realValues } = values;
      delete realValues.attributeValues;
      return onSubmit({
        ...realValues,
        "keycloak-enabled": true
      });
    },
    [onSubmit],
  );

  return (
    <FormProvider
      initialValues={{
        ...attributeValues,
        [FAKE_KEYCLOAK_URL_KEY]: getKeycloakUrl(),
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ dirty }) => (
        <Form m="0 1rem" maw="32.5rem">
          <Breadcrumbs
            className="mb3"
            crumbs={[
              [t`Audaxis`, "/admin/settings/audaxis"],
              [t`Keycloak`],
            ]}
          />
          <h2 className="mb2">{t`Set up Keycloak`}</h2>
          <KeycloakFormSection>
            <h3 className="mb0">{t`Configure your identity provider for Metabase connection`}</h3>
            <FormSection>
              <h3 className="mb0">{t`Configure your identity provider`}</h3>
              <FormTextInput
                  name={FAKE_KEYCLOAK_URL_KEY}
                  description={t``}
                  hasCopyButton
                  readOnly
                  label={t`URL the IdP should redirect back to`}
              />
              <Stack>
                <FormTextInput
                    {...fields["keycloak-email"]}
                    hasCopyButton
                    label={t`Email attribute`}
                />
                <FormTextInput
                    {...fields["keycloak-firstname"]}
                    hasCopyButton
                    label={t`First name attribute`}
                />
                <FormTextInput
                    {...fields["keycloak-lastname"]}
                    hasCopyButton
                    label={t`Last name attribute`}
                />
              </Stack>
            </FormSection>
          </KeycloakFormSection>
          <KeycloakFormSection>
            <h3 className="mb0">{t`Configure your identity provider for Apiz connection`}</h3>
            <FormSection>
              <Stack>
                <FormTextInput
                    {...fields["apiz-email"]}
                    hasCopyButton
                    label={t`Email attribute`}
                />
                <FormTextInput
                    {...fields["apiz-firstname"]}
                    hasCopyButton
                    label={t`First name attribute`}
                />
                <FormTextInput
                    {...fields["apiz-lastname"]}
                    hasCopyButton
                    label={t`Last name attribute`}
                />
              </Stack>
            </FormSection>
          </KeycloakFormSection>

          <KeycloakFormSection>
            <h3 className="mb0">{t`Information about your identity provider`}</h3>
            <Stack>
              <FormTextInput
                  {...fields["keycloak-identity-provider-uri"]}
                  label={t`Keycloak Identity Provider URL`}
                  placeholder="https://keycloak.yourIDP.com"
                  required
              />
              <FormTextarea
                  {...fields["keycloak-identity-provider-certificate"]}
                  label={t`Keycloak Identity Provider Certificate`}
                  required
              />
              <FormTextInput
                  {...fields["keycloak-application-name-sso"]}
                  label={t`Keycloak Application Name`}
              />
              <FormTextInput
                  {...fields["keycloak-identity-provider-issuer"]}
                  label={t`Keycloak Identity Provider Issuer`}
              />
            </Stack>
          </KeycloakFormSection>
          <KeycloakFormSection>
            <FormSection>
              <h3 className="mb0">{t`Synchronize group membership with your SSO`}</h3>
              <Stack>
                <GroupMappingsWidget
                  isFormik
                  setting={{key: "keycloak-group-sync"}}
                  onChange={handleSubmit}
                  settingValues={settingValues}
                  mappingSetting="keycloak-group-mappings"
                  groupHeading={t`Group Name`}
                  groupPlaceholder={t`Group Name`}
                />
                <FormTextInput
                  {...fields["keycloak-group"]}
                  label={t`Group attribute name`}
                />
                <FormTextInput
                  {...fields["keycloak-group-requirement"]}
                  label={t`Group required attribute name`}
                />
              </Stack>
            </FormSection>
            <KeycloakFormFooter>
              <FormErrorMessage/>
              <FormSubmitButton
                disabled={!dirty}
                label={isEnabled ? t`Save changes` : t`Save and enable`}
                variant={"filled"}
              />
            </KeycloakFormFooter>
          </KeycloakFormSection>
        </Form>
      )}
    </FormProvider>
  );
};

const IS_KEYCLOAK_ATTR_DEFAULTABLE = {
  "keycloak-user-provisioning-enabled": true,
  "keycloak-email": true,
  "keycloak-firstname": true,
  "keycloak-lastname": true,
  "keycloak-group-requirement" : true,
  "keycloak-identity-provider-uri": true,
  "keycloak-identity-provider-issuer": true,
  "keycloak-identity-provider-certificate": true,
  "keycloak-application-name": true,
  "keycloak-group": false,
  "keycloak-group-sync": false,
};

const getAttributeValues = (
  values, defaults
) => {
  return Object.fromEntries(
    Object.entries(IS_KEYCLOAK_ATTR_DEFAULTABLE).map(([key, isDefaultable]) => [
      key,
      isDefaultable ? values[key] ?? defaults[key] : values[key],
    ]),
  );
};

const getKeycloakUrl = () => {
  return `${MetabaseSettings.get("site-url")}/api/sso`
}

const mapDispatchToProps = {
  onSubmit: updateKeycloakSettings,
}

export const AudaxisAuthForm = connect(
  null,
  mapDispatchToProps,
)(AudaxisAuthFormView);


