var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var zK,BK,DK,GK,JK;$CLJS.wK=function(a){return $CLJS.Hh($CLJS.q($CLJS.fB),a,$CLJS.nF)};$CLJS.xK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.yK=new $CLJS.M(null,"column-ref","column-ref",2018188376);zK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.AK=new $CLJS.M(null,"operators","operators",-2064102509);
BK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.CK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);DK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.EK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.FK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
GK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.HK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.IK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);JK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.KK=new $CLJS.M(null,"null","null",-180137709);
$CLJS.LK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(BK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pF,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.dD],null)],null)],null));$CLJS.Y(JK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,BK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.zD],null)],null)],null));$CLJS.Y(DK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.jF],null),JK,$CLJS.qD],null));
$CLJS.Y(GK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.jF],null),BK,$CLJS.eK],null));
$CLJS.IF.g($CLJS.jF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.jF],null),BK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.eK,$CLJS.qD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.si,function(a){return $CLJS.rd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.WA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,GK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,DK],null)],null)],null));$CLJS.pE($CLJS.jF,$CLJS.nF);$CLJS.KD.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ki,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.YD});$CLJS.wE($CLJS.qA,$CLJS.H([$CLJS.qD]));$CLJS.KD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ki,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.YD});
$CLJS.pE($CLJS.qA,$CLJS.nF);$CLJS.Y(zK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xK,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.qD],null)],null)],null));
$CLJS.IF.g($CLJS.ZE,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.ZE],null),zK,$CLJS.nj],null));$CLJS.KD.m(null,$CLJS.ZE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ki,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.YD});$CLJS.pE($CLJS.ZE,$CLJS.nF);$CLJS.wE($CLJS.HE,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LJ],null)],null)]));$CLJS.pE($CLJS.HE,$CLJS.nF);
$CLJS.wE($CLJS.eF,$CLJS.H([$CLJS.Zr,$CLJS.YD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JJ],null)],null)]));$CLJS.pE($CLJS.eF,$CLJS.nF);
$CLJS.Y($CLJS.nF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.MD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Dd,$CLJS.rk.g($CLJS.q($CLJS.fB),$CLJS.nF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.wK(a)}],null)],null));