import {PLUGIN_AUTH_PROVIDERS, PLUGIN_IS_PASSWORD_USER} from "metabase/plugins";
import {KeycloakButton} from "metabase/admin/settings/audaxis/components/AudaxisAuthForm/KeycloakButton";
import MetabaseSettings from "metabase/lib/settings";

PLUGIN_AUTH_PROVIDERS.push(providers => {
  const keycloakProvider = {
    name: "keycloak",
    Button: KeycloakButton,
  };
  return [keycloakProvider, ...providers];
});

PLUGIN_IS_PASSWORD_USER.push(user => user.sso_source !== "keycloak");
