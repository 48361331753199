import styled from "@emotion/styled";
import {color} from "metabase/lib/colors";

export const KeycloakFormSection = styled.div`
  padding: 1rem 2rem ;
  margin-bottom: 1rem;
  border: 1px solid ${color("border")};
  border-radius: 0.5rem;

  input {
    max-width: 460px;
  }
`;
export const KeycloakFormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  margin-bottom: 1rem;
`;
