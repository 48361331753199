var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var NH,PH,RH,$H,cI,hI;$CLJS.MH=new $CLJS.M(null,"get-month","get-month",-369374731);NH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.OH=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);PH=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.QH=new $CLJS.M(null,"iso","iso",-1366207543);RH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.SH=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.TH=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.UH=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.VH=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.WH=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.XH=new $CLJS.M(null,"us","us",746429226);$CLJS.YH=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.ZH=new $CLJS.M(null,"now","now",-1650525531);
$H=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.aI=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.bI=new $CLJS.M(null,"get-day","get-day",127568857);cI=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.dI=new $CLJS.M(null,"get-minute","get-minute",1468086343);
$CLJS.eI=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.fI=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);$CLJS.gI=new $CLJS.M(null,"get-hour","get-hour",622714059);hI=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.iI=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);
$CLJS.jI=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.kI=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.lI=new $CLJS.M(null,"target","target",253001721);$CLJS.wE($CLJS.YA,$CLJS.H([$CLJS.Zr,$CLJS.$B,$CLJS.pr,$CLJS.cD]));$CLJS.KD.m(null,$CLJS.VH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.LD(a);return $CLJS.qd(a)?(a=$CLJS.ct.g(a,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.wk,null,$CLJS.Ck,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.pE($CLJS.VH,$CLJS.bE);
for(var mI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aB,$CLJS.dB],null)),nI=null,oI=0,pI=0;;)if(pI<oI){var qI=nI.X(null,pI);$CLJS.wE(qI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null),$CLJS.pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)]));$CLJS.pE(qI,$CLJS.VH);pI+=1}else{var rI=$CLJS.y(mI);if(rI){var sI=rI;if($CLJS.vd(sI)){var tI=$CLJS.ic(sI),xia=$CLJS.jc(sI),yia=tI,zia=$CLJS.D(tI);mI=xia;nI=yia;oI=zia}else{var uI=$CLJS.z(sI);$CLJS.wE(uI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.UD],null),$CLJS.pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)]));$CLJS.pE(uI,$CLJS.VH);mI=$CLJS.B(sI);nI=null;oI=0}pI=0}else break}
for(var vI=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.TH,$CLJS.MH,$CLJS.bI,$CLJS.gI,$CLJS.dI,$CLJS.SH,$CLJS.iI],null)),wI=null,xI=0,yI=0;;)if(yI<xI){var Aia=wI.X(null,yI);$CLJS.wE(Aia,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));yI+=1}else{var zI=$CLJS.y(vI);if(zI){var AI=zI;if($CLJS.vd(AI)){var BI=$CLJS.ic(AI),Bia=$CLJS.jc(AI),Cia=BI,Dia=$CLJS.D(BI);vI=Bia;wI=Cia;xI=Dia}else{var Eia=$CLJS.z(AI);$CLJS.wE(Eia,$CLJS.H([$CLJS.Zr,
$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));vI=$CLJS.B(AI);wI=null;xI=0}yI=0}else break}$CLJS.wE($CLJS.XA,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XF],null)]));
for(var CI=$CLJS.y(new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.iI,null,$CLJS.dI,null,$CLJS.gI,null,$CLJS.eI,null,$CLJS.SH,null,$CLJS.MH,null,$CLJS.TH,null,$CLJS.bI,null],null),null)),DI=null,EI=0,FI=0;;)if(FI<EI){var Fia=DI.X(null,FI);$CLJS.wE(Fia,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));FI+=1}else{var GI=$CLJS.y(CI);if(GI){var HI=GI;if($CLJS.vd(HI)){var II=$CLJS.ic(HI),Gia=$CLJS.jc(HI),Hia=II,Iia=$CLJS.D(II);
CI=Gia;DI=Hia;EI=Iia}else{var Jia=$CLJS.z(HI);$CLJS.wE(Jia,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));CI=$CLJS.B(HI);DI=null;EI=0}FI=0}else break}$CLJS.Y(hI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.QH,$CLJS.XH,$CLJS.aI],null));
$CLJS.yE($CLJS.$A,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hI],null)],null)],null)],null)]));
$CLJS.Y($CLJS.fI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.qD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,2,[$CLJS.ts,"valid timezone ID",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Zi);return["invalid timezone ID: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.Cz($CLJS.Dd,$CLJS.LH.tz.names())),$CLJS.CH],null)],null));
$CLJS.yE($CLJS.OH,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yy,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fI],null)],null)],null)],
null)]));$CLJS.pE($CLJS.OH,$CLJS.VH);$CLJS.wE($CLJS.ZH,$CLJS.H([$CLJS.Zr,$CLJS.zk]));$CLJS.Y(RH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Me(function(b){return $CLJS.Dz(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wk,$CLJS.Ck],null))}],null)],null));
$CLJS.Y(NH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RH],null)],null)],null)],null));
$CLJS.IF.g($CLJS.UH,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.ts,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.UH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Uq,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YF],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.Qu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UF],null)],null)],null)],null)],null)],null));
$CLJS.KD.m(null,$CLJS.UH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Qu)?$CLJS.E.g(a,$CLJS.Oh)?$CLJS.Ck:$CLJS.n($CLJS.oE($CLJS.YF,a))?$CLJS.wk:$CLJS.Ck:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.dh($CLJS.wH,b))?$CLJS.wk:$CLJS.n($CLJS.dh($CLJS.xH,b))?$CLJS.wk:null:null;if($CLJS.n(a))return a;b=$CLJS.LD(b);b=$CLJS.qd(b)?$CLJS.Wf.j($CLJS.Sg,$CLJS.ef(function(c){return $CLJS.Dz(c,$CLJS.lj)}),b):b;return $CLJS.qd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.Y(cI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.Qu],null),$CLJS.pr],null));
$CLJS.yE($CLJS.ZA,$CLJS.H([$CLJS.Zr,$CLJS.Ck,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)],null)],null)],null)]));
$CLJS.wE($CLJS.Fx,$CLJS.H([$CLJS.Zr,$CLJS.Lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bD],null)]));$CLJS.Y(PH,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ar,$CLJS.WF,$CLJS.xk,$CLJS.Ik,$CLJS.WH,$CLJS.jI,$CLJS.kI,$CLJS.Fk,$CLJS.yk,$CLJS.vk,$CLJS.Bk,$CLJS.TF],null));$CLJS.Y($H,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.QH,$CLJS.XH,$CLJS.aI],null));
$CLJS.yE($CLJS.bB,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$H],null)],null)],
null)],null)]));