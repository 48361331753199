var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var j7,k7,l7,ota,pta,qta,o7,p7,r7,s7,rta,sta,tta,u7,v7,w7,uta,vta;j7=function(a){var b=new $CLJS.h(null,3,[$CLJS.xD,$CLJS.p.h($CLJS.HD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ki,$CLJS.zz($CLJS.ki,$CLJS.tA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,b,$CLJS.zz($CLJS.pD,$CLJS.U)(a)],null)};k7=function(a){return $CLJS.R.j(a,$CLJS.Vs,$CLJS.A4)};l7=function(a,b){return $CLJS.E.g($CLJS.kD.h(a),$CLJS.Cl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BD,$CLJS.jD,$CLJS.AD],null)))};
ota=function(a){return $CLJS.N0(a,new $CLJS.h(null,1,[$CLJS.BD,$CLJS.mh],null))};
pta=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.pG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IY,$CLJS.U],null));if($CLJS.n(c))return c;c=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",c))){var d=$CLJS.gD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.Yj.g($CLJS.IY,b)]))]));d instanceof Error?$CLJS.Kz("metabase.lib.field",c,$CLJS.xv(),d):$CLJS.Kz("metabase.lib.field",
c,$CLJS.xv.l($CLJS.H([d])),null)}return null};
qta=function(a,b,c){if($CLJS.n(m7))return null;var d=m7;m7=!0;try{var e=$CLJS.G1(a,b),f=$CLJS.n(e)?$CLJS.CV(a,e):$CLJS.CV(a,b),k=function(){var m=$CLJS.n7.h(f);if($CLJS.n(m))return m;m=$CLJS.Bz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lU,$CLJS.zV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.LU.h(f);if($CLJS.n(u))return u;u=$CLJS.cO.h(f);if($CLJS.n(u))return u;u=$CLJS.XO.h(f);return $CLJS.n(u)?u:$CLJS.RD.h(f)}())?$CLJS.V_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",
m))){var t=$CLJS.gD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Kz("metabase.lib.field",m,$CLJS.xv(),t):$CLJS.Kz("metabase.lib.field",m,$CLJS.xv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?pta(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.g3($CLJS.Pj.l(l,$CLJS.Ci,$CLJS.H([$CLJS.XJ,$CLJS.LV,$CLJS.GV])),null),$CLJS.U,function(){var m=$CLJS.IY.h(l);return $CLJS.n(m)?m:$CLJS.U.h(l)}()),
$CLJS.KJ,$CLJS.tY):l:null}finally{m7=d}};
o7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.rO);var f=$CLJS.I(c,2,null);c=$CLJS.ok.l($CLJS.H([function(){var k=$CLJS.tA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.tA,k],null):null}(),function(){var k=$CLJS.zz($CLJS.ki,$CLJS.tA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.ki,k],null):null}(),function(){var k=$CLJS.rQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.LV,k],null):null}(),function(){var k=$CLJS.pF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.GV,k],null):null}(),$CLJS.Ad(f)?function(){var k=$CLJS.M1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.IJ,$CLJS.U,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.IJ,$CLJS.U,$CLJS.p.h(f)],null):function(){var k=qta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.IJ,$CLJS.U,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.g3(c,d):c};
p7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.GV);return $CLJS.n($CLJS.n(b)?$CLJS.Cd($CLJS.mG,b):b)?$CLJS.Ti:$CLJS.zz($CLJS.ki,$CLJS.tA)(a)};
$CLJS.q7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.xD),l=$CLJS.J.g(f,$CLJS.tA),m=$CLJS.J.g(f,$CLJS.rQ),t=$CLJS.J.g(f,$CLJS.ki);e=$CLJS.J.g(f,$CLJS.rO);var u=$CLJS.J.g(f,$CLJS.CM),v=$CLJS.J.g(f,$CLJS.pF),x=$CLJS.ok.l;k=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.IJ,$CLJS.A0,k],null);f=$CLJS.lD.h(f);a=$CLJS.n(f)?f:$CLJS.i_.j(a,b,d);c=x.call($CLJS.ok,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.lD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.ki,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.tA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.GV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.LV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.WJ,u):m;return $CLJS.n(e)?$CLJS.g3(u,e):u};r7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.bi.h(d),c)},a))?$CLJS.Yj.g(function(d){var e=$CLJS.Cd(d,b)?$CLJS.Pj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.bi.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
s7=function(a){var b=$CLJS.n($CLJS.n1.h(a))?null:function(){var e=$CLJS.KJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.MY,null,$CLJS.TY,null,$CLJS.tY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.ok.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.xD,$CLJS.p.h($CLJS.HD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ki,p7(a)],null),function(){var e=function(){var f=$CLJS.Ra(b);return f?(f=$CLJS.Ra($CLJS.WJ.h(a)))?(f=$CLJS.Vj.g($CLJS.EY,$CLJS.KJ.h(a)))?$CLJS.XY.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.rO,e],null):null}(),function(){var e=$CLJS.g1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.rO,e],null):null}(),function(){var e=$CLJS.GV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.pF,e],null):null}(),function(){var e=$CLJS.KV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.KV,e],null):null}(),function(){var e=$CLJS.LV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.rQ,e],null):null}(),function(){var e=$CLJS.WJ.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.CM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.zz($CLJS.IY,$CLJS.U):$CLJS.zz($CLJS.Ci,$CLJS.U))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,c,d],null)};rta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.KJ.h(c),$CLJS.CY)},$CLJS.V_.v(a,b,$CLJS.CV(a,b),new $CLJS.h(null,3,[$CLJS.C_,!1,$CLJS.I_,!0,$CLJS.y_,!1],null)))};sta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);tta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.t7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);u7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);v7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.n7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);w7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
uta=new $CLJS.M(null,"earliest","earliest",-1928154382);vta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.O0.m(null,$CLJS.jF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.N0(c,new $CLJS.h(null,2,[$CLJS.pF,$CLJS.mh,$CLJS.rQ,ota],null)),a],null)});
var m7=!1,x7=function x7(a,b){var d=$CLJS.X0(a,v7.h(b));a=$CLJS.n(v7.h(d))?x7.g?x7.g(a,d):x7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.U),f=$CLJS.J.g(a,$CLJS.lD);return $CLJS.Zj.j($CLJS.R.j($CLJS.Zj.j($CLJS.R.j(b,sta,$CLJS.U.h(b)),$CLJS.U,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),w7,$CLJS.lD.h(b)),$CLJS.lD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.N_.m(null,$CLJS.IJ,function(a,b,c){return p7(c)});
$CLJS.N_.m(null,$CLJS.jF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.pF);$CLJS.I(c,2,null);c=o7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.GV,d):c;return $CLJS.O_.j(a,b,c)});$CLJS.P_.m(null,$CLJS.IJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);return $CLJS.R.j(a,$CLJS.U,b)});$CLJS.P_.m(null,$CLJS.jF,function(a,b,c){var d=o7(a,b,c);b=$CLJS.q7(a,b,d,c);return $CLJS.n(v7.h(b))?x7(a,b):b});
$CLJS.J_.m(null,$CLJS.IJ,function(a,b,c,d){function e(T){var da=$CLJS.g5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}function f(T){var da=$CLJS.cA($CLJS.Gz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}var k=$CLJS.O(c),l=$CLJS.J.g(k,$CLJS.LV),m=$CLJS.J.g(k,$CLJS.WJ),t=$CLJS.J.g(k,$CLJS.XJ),u=$CLJS.J.g(k,$CLJS.bi),v=$CLJS.J.g(k,$CLJS.XY),x=$CLJS.J.g(k,w7),A=$CLJS.J.g(k,$CLJS.U),C=$CLJS.J.g(k,$CLJS.G5),G=$CLJS.J.g(k,
$CLJS.lD),K=$CLJS.J.g(k,v7),S=$CLJS.e1.g($CLJS.d1,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(K)?null==G||$CLJS.E.g(G,S):K)){a:{var T=$CLJS.O(k);var da=$CLJS.J.g(T,$CLJS.lD);T=$CLJS.J.g(T,v7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(T)){T=$CLJS.X0(a,T);var oa=$CLJS.O(T);T=$CLJS.J.g(oa,$CLJS.lD);oa=$CLJS.J.g(oa,v7);da=$CLJS.Yd.g(da,T);T=oa}else break a}da=$CLJS.Le($CLJS.Sa,da)?$CLJS.hs(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.j_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var T=$CLJS.X0(a,m);$CLJS.n(T)?T=$CLJS.c3($CLJS.lD.h($CLJS.l_.j(a,b,T))):(T=$CLJS.f1(a,t),T=$CLJS.i_.v(a,b,T,d))}else T=null;return $CLJS.n(T)?T:$CLJS.n(v)?v:$CLJS.g1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var T=$CLJS.Vj.g(d,$CLJS.j_);return T?C:T}())?c:$CLJS.n($CLJS.n(u)?$CLJS.Vj.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.Vj.g(c,e(S)):l)?e(c):c});
$CLJS.J_.m(null,$CLJS.jF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.rQ);var k=$CLJS.J.g(f,$CLJS.rO),l=$CLJS.J.g(f,$CLJS.pF);f=$CLJS.J.g(f,$CLJS.CM);$CLJS.I(c,2,null);c=o7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.XY,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.bi,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.LV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.WJ,f):e;return $CLJS.n(e)?$CLJS.i_.v(a,b,e,d):$CLJS.ID("[Unknown Field]")});
$CLJS.K_.m(null,$CLJS.IJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.U)});$CLJS.K_.m(null,$CLJS.jF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=o7(a,b,c);return $CLJS.n(c)?$CLJS.L_.j(a,b,c):"unknown_field"});
$CLJS.R_.m(null,$CLJS.IJ,function(a,b,c){return $CLJS.ok.l($CLJS.H([function(){var d=$CLJS.iH($CLJS.R_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.i_.j(a,b,c),$CLJS.k_,$CLJS.i_.v(a,b,c,$CLJS.j_)],null),$CLJS.E.g($CLJS.KJ.h(c),$CLJS.MY)?function(){var d=$CLJS.WY.h(c);return $CLJS.n(d)?(d=$CLJS.c_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.Bj,new $CLJS.h(null,2,[$CLJS.U,$CLJS.U.h(d),$CLJS.lD,$CLJS.U.h(d)],null)],null):null):null}():null]))});
$CLJS.r0.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.pF.h(b)});$CLJS.r0.m(null,$CLJS.IJ,function(a){return $CLJS.GV.h(a)});
$CLJS.q0.m(null,$CLJS.jF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Cd($CLJS.mG,b),e=$CLJS.pB($CLJS.KV,$CLJS.ki,$CLJS.tA)(c);c=$CLJS.R.l(c,$CLJS.pF,b,$CLJS.H([$CLJS.ki,d?$CLJS.Ti:e,$CLJS.KV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,c,a],null)}b=$CLJS.KV.h(c);c=$CLJS.n(b)?$CLJS.Pj.g($CLJS.R.j(c,$CLJS.ki,b),$CLJS.KV):c;c=$CLJS.Pj.g(c,$CLJS.pF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,c,a],null)});
$CLJS.q0.m(null,$CLJS.IJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.GV,b,$CLJS.H([$CLJS.KV,$CLJS.pB($CLJS.KV,$CLJS.ki,$CLJS.tA)(a)])):$CLJS.Pj.l(a,$CLJS.GV,$CLJS.H([$CLJS.KV]))});$CLJS.t0.m(null,$CLJS.jF,function(a,b,c){return $CLJS.u0.j(a,b,o7(a,b,c))});
$CLJS.t0.m(null,$CLJS.IJ,function(a,b,c){if($CLJS.Vj.g($CLJS.KJ.h(c),$CLJS.CY)){a=$CLJS.zz($CLJS.ki,$CLJS.tA)(c);b=null==c?null:$CLJS.QM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Ck.h($CLJS.Mi.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,uta),l=$CLJS.J.g(f,vta),m=$CLJS.kpa.l($CLJS.H([$CLJS.o0.h(k),$CLJS.o0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Bq.g?$CLJS.Bq.g(1,m):$CLJS.Bq.call(null,1,m))?$CLJS.Ak:$CLJS.n($CLJS.Bq.g?$CLJS.Bq.g(31,m):$CLJS.Bq.call(null,31,m))?$CLJS.Kk:$CLJS.n($CLJS.Bq.g?
$CLJS.Bq.g(365,m):$CLJS.Bq.call(null,365,m))?$CLJS.Oi:$CLJS.Ui}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.Dz(a,$CLJS.Ck)?$CLJS.vpa:$CLJS.Dz(a,$CLJS.wk)?$CLJS.upa:$CLJS.Dz(a,$CLJS.Lk)?$CLJS.tpa:$CLJS.xf;d=$CLJS.n(d)?r7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.GV.h(c))?r7(d,$CLJS.q_,$CLJS.GV.h(c)):d}return $CLJS.xf});
$CLJS.d5.m(null,$CLJS.jF,function(a){var b=null==a?null:$CLJS.ED(a);b=null==b?null:$CLJS.rQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.i5,$CLJS.H([$CLJS.h5,function(c,d){return o7(c,d,a)}]))});$CLJS.d5.m(null,$CLJS.IJ,function(a){var b=null==a?null:$CLJS.LV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.i5,$CLJS.H([$CLJS.h5,$CLJS.Pe(a)]))});$CLJS.j5.m(null,$CLJS.jF,function(a,b){return $CLJS.FD(a,$CLJS.DD,$CLJS.H([$CLJS.rQ,b]))});
$CLJS.j5.m(null,$CLJS.IJ,function(a,b){return $CLJS.DD(a,$CLJS.LV,b)});$CLJS.k5.m(null,$CLJS.jF,function(a,b,c){return $CLJS.l5.j(a,b,o7(a,b,c))});
$CLJS.k5.m(null,$CLJS.IJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.ki);var d=$CLJS.J.g(b,$CLJS.QM),e=$CLJS.J.g(b,$CLJS.kj);if($CLJS.Vj.g($CLJS.KJ.h(b),$CLJS.CY)){var f=function(){var m=null==a?null:$CLJS.W0(a);m=null==m?null:$CLJS.dz.h(m);return null==m?null:$CLJS.Cd(m,$CLJS.rQ)}(),k=$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,$CLJS.gj],null)),l=$CLJS.e5(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.vd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.hd(x,K),X=C,T=S;S=l7(S,l)?$CLJS.R.j(T,$CLJS.q_,!0):T;X.add(S);K+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var K=G;return l7(G,l)?$CLJS.R.j(K,$CLJS.q_,!0):K}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Ra(function(){if($CLJS.n(f)){var t=$CLJS.ni.h(k);return $CLJS.n(t)?$CLJS.tj.h(k):t}return f}())?null:$CLJS.Dz(e,$CLJS.Vi)?$CLJS.Yj.g(k7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.f5(),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("Bin every 0.1 degrees"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.AD,$CLJS.AD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("Bin every 1 degree"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.AD,$CLJS.AD,1],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("Bin every 10 degrees"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.AD,$CLJS.AD,10],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("Bin every 20 degrees"),$CLJS.kD,new $CLJS.h(null,
2,[$CLJS.BD,$CLJS.AD,$CLJS.AD,20],null)],null)],null)):$CLJS.Dz(c,$CLJS.gj)&&!$CLJS.Dz(e,$CLJS.Zh)?$CLJS.Yj.g(k7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.f5(),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("10 bins"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.jD,$CLJS.jD,10],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("50 bins"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.jD,$CLJS.jD,50],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("100 bins"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.jD,$CLJS.jD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.lV.m(null,$CLJS.jF,function(a){return a});
$CLJS.lV.m(null,$CLJS.IJ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.KJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.xD,$CLJS.p.h($CLJS.HD()),$CLJS.ki,$CLJS.zz($CLJS.ki,$CLJS.tA)(a),$CLJS.xK,$CLJS.U.h(a)],null),a=$CLJS.A0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,b,a],null);case "source/expressions":return j7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.pD.h(a))?j7(a):s7(a);default:return s7(a)}});
$CLJS.wta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.Yj.g($CLJS.nV,k));var l=rta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.ul(function(t){return $CLJS.S1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.Xj.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.nV),m):null;return $CLJS.NV.l(e,f,$CLJS.DD,$CLJS.H([$CLJS.RD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.y7=function(){function a(d,e){return $CLJS.RD.h($CLJS.CV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.xta=function(){function a(d,e){var f=$CLJS.V_.v(d,e,$CLJS.CV(d,e),new $CLJS.h(null,3,[$CLJS.C_,!1,$CLJS.I_,!1,$CLJS.y_,!1],null)),k=$CLJS.y7.g(d,e);return $CLJS.od(k)?$CLJS.Yj.g(function(l){return $CLJS.R.j(l,$CLJS.KY,!0)},f):$CLJS.e3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(u7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.Yi,$CLJS.wY,$CLJS.cz],null));
$CLJS.Y(tta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.t7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,u7],null)],null)],null));