import {useDispatch} from "metabase/lib/redux";
import {useCallback, useEffect} from "react";
import {AuthButton} from "metabase/auth/components/AuthButton";
import {t} from "ttag";
import {loginKeycloak} from "metabase/admin/settings/audaxis/components/AudaxisAuthForm/action";
import {isWithinIframe} from "metabase/lib/dom";

interface KeycloakButtonProps {
  isCard?: boolean;
  redirectUrl?: string;
}

export const KeycloakButton = ({
                            isCard,
                            redirectUrl,
                          }: KeycloakButtonProps): JSX.Element => {
  const isEmbedded = isWithinIframe();
  const dispatch = useDispatch();

  const handleLogin = useCallback(() => {
    dispatch(loginKeycloak(redirectUrl));
  }, [dispatch, redirectUrl]);

  useEffect(() => {
    if (isEmbedded) {
      handleLogin();
    }
  }, [isEmbedded, handleLogin]);

  return (
    <AuthButton isCard={isCard} onClick={handleLogin}>
      {t`Sign in with Keycloak`}
    </AuthButton>
  );
};
