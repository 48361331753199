import { t } from "ttag";

import {Button, type ButtonProps, Text, Title} from "metabase/ui";
import {Link} from "react-router";
import {StyledCard} from "metabase/admin/settings/components/widgets/EmbeddingOption/EmbeddingOption.styled";

export const AudaxisWidget = ({}) => (
  <StyledCard compact>
    <Title>Audaxis</Title>
    <Text lh={"1.25rem"} mb={"lg"}>
      {t`Allows users to login via a Keycloak Identity Provider.`}
    </Text>
    <LinkButton
      variant="default"
      to={"/admin/settings/audaxis/keycloak"}
    >
      {t`Set up`}
    </LinkButton>
  </StyledCard>
);

const LinkButton = ({
                      to,
                      disabled,
                      children,
                    }: { to: string; disabled?: boolean; children: React.ReactNode }) => {
  const ButtonComponent = disabled ? Button : Link;
  return (
    <ButtonComponent to={to} disabled={disabled}>
      {children}
    </ButtonComponent>
  );
};
