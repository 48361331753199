import type {State} from "metabase-types/store";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {getSetting} from "metabase/selectors/settings";
import {redirect} from "metabase/lib/dom";

interface ThunkConfig {
  state: State;
}

export const LOGIN_SSO = "metabase/admin/settings/audaxis/components/AudaxisAuthForm";

export const loginKeycloak = createAsyncThunk<void, string | undefined, ThunkConfig>(
  LOGIN_SSO,
  (redirectUrl: string | undefined, { getState }) => {
    const siteUrl = getSetting(getState(), "site-url");
    const ssoUrl = getKeycloakUrl(siteUrl, redirectUrl);

    redirect(ssoUrl);
  },
);

export const getKeycloakUrl = (siteUrl: string, redirectUrl?: string): string => {
  if (redirectUrl) {
    return `${siteUrl}/api/sso?redirect=${encodeURIComponent(redirectUrl)}`;
  } else {
    return `${siteUrl}/api/sso`;
  }
};
